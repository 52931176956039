<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import CardIconLabelLocal from '@/components/CardIconLabelLocal'
import { Button } from 'ant-design-vue'
import { thirdTypeDataList, operatingCycle } from '@/utils/textFile'
import DrawerForm from '@/components/DrawerForm'

export default {
  name: 'scenic-areas-detail',
  data() {
    return {
      ...api.command.getState(),
      labelGroup: [],
      detail: {
        businessCycle: [],
        list: [],
        startTime: '00:00:00',
        endTime: '00:00:00',
        scenicBlurbList: [],
        businessTimeType: '0',
      },
      storeData: [],
      typeDataZQ: operatingCycle,
      businessCycle: ['1', '2', '3', '4', '5', '6', '7'],
      jwdAyy: [],
      areaCode: [],
      language_type: [],
    }
  },
  mounted() {
    this.initThreeTree()
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmTicketScenic/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.linkShopName)
          this.detail = {
            ...res,
          }

          this.detail['businessCycle'] = res.businessCycle ? res.businessCycle.split('、')[0].split(',') : []
          this.detail.scenicBlurbList = this.detail.scenicBlurbList ?? []
          this.detail.areaCode = [res.provinceCode, res.cityCode, res.areaCode]
          this.detail.dw = `${res.lon}，${res.lat}`
          this.$forceUpdate()
        })
    }
    getAction('/admin/dict/codes?codes=language_type', {}, '/bpi').then((e) => {
      this.language_type = e.data['language_type'] || []
    })
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'scenic_label',
          },
        ],
      },
    })
  },
  methods: {
    getFilterData() {
      return this.typeDataZQ.filter((e) => this.detail.businessCycle.indexOf(e.value) !== -1)
    },
    initThreeTree() {
      const { id } = this.$route.query
      getAction('/config/point/queryPointList', {
        type: 2,
      }).then((res) => {
        if (res.code == 200) {
          this.jwdAyy = res.data.map((e) => {
            if (id == e.id) {
              let icon = new AMap.Icon({
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                imageSize: new AMap.Size(20, 40),
              })
              return {
                lng: e.longitude,
                lat: e.latitude,
                icon: icon,
                id: e.id,
              }
            } else {
              return {
                lng: e.longitude,
                lat: e.latitude,
                id: e.id,
              }
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })

      // 获取级联数据
      api.command.getRegionType.call(this, {
        url: '/api/region/threeTree',
        key: 'areaCode',
      })
    },
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    /** end 数据查询 */

    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '购票',
            type: 'switch',
            key: 'switchTicket',
            cols: 12,
          },
          {
            name: '简介',
            type: 'switch',
            key: 'switchBlurb',
            cols: 12,
          },
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 40,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'switch',
            key: 'scoreShowType',
            name: '开启自定义评分',
            cols: 3,
            reversal: true,
          },
          {
            name: '评分',
            slotTitle: () => {
              return (
                <span>
                  评分
                  <span style={{ marginLeft: '5px', color: '#e5e5e5' }}>(不填默认用真实评分)</span>
                </span>
              )
            },
            type: 'input',
            cols: 4,
            key: 'modifScore',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '排序序号（数字越小排序越前，不填默认按创建时间倒序',
            type: 'input',
            cols: 12,
            key: 'sort',
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShopName',
            onInputSearch: this.storeSearch,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '景区类型',
            type: 'select',
            key: 'scenicType',
            cols: 12,
            typeData: [
              {
                name: '景点门票',
                value: '0',
              },
              {
                name: '博物馆',
                value: '1',
              },
              {
                name: '休闲玩乐',
                value: '2',
              },
            ],
          },
          {
            type: 'switch',
            key: 'telephoneButton',
            name: '联系方式开关',
            cols: 3,
          },
          {
            name: '联系方式',
            type: 'input',
            cols: 9,
            key: 'telephone',
          },
          {
            name: '客服手机号（接收确认订单短信）',
            type: 'input',
            cols: 12,
            key: 'customerPhone',
          },
          {
            type: 'radioButton',
            name: '营业类型',
            key: 'businessTimeType',
            cols: 6,
            typeData: [
              {
                name: '一天营业时间',
                value: '0',
              },
              {
                name: '上午下午营业时间',
                value: '1',
              },
            ],
          },
          {
            name: '营业周期',
            type: 'select',
            cols: 6,
            props: {
              mode: 'multiple',
            },
            sort: true,
            key: 'businessCycle',
            typeData: this.typeDataZQ,
          },
          {
            type: 'row',
            cols: 12,
            gutter: 20,
            display: this.detail.businessTimeType == '0',
            children: [
              {
                name: '营业开始时间',
                type: 'timePicker',
                cols: 6,
                key: 'startTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
              {
                name: '营业结束时间',
                type: 'timePicker',
                cols: 6,
                key: 'endTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
            ],
          },
          {
            type: 'row',
            cols: 6,
            gutter: 20,
            display: this.detail.businessTimeType == '1',
            children: [
              {
                name: '上午营业时间',
                type: 'timePickerRange',
                cols: 12,
                key: 'openTimeAm',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
              {
                name: '下午营业时间',
                type: 'timePickerRange',
                cols: 12,
                key: 'openTimePm',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
            ],
          },
          {
            type: 'iconUpload',
            cols: 4,
            key: 'a12',
            name: '首页预览图',
            typeData: [
              {
                maxLength: 1,
                key: 'bigImg',
              },
            ],
          },
          {
            type: 'textArea',
            name: '时间备注',
            key: 'timeNote',
            maxLength: 300,
            cols: 12,
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    getForm2() {
      return {
        title: '位置',
        type: 'cardForm',
        data: [
          {
            name: '经纬度',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                placeholder: '请定位所在位置',
                rules: [
                  {
                    required: true,
                    type: 'string',
                    message: '请定位所在位置',
                  },
                ],
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'areaCode',
                label: '地址',
                typeData: this.areaCode,
                placeholder: '请选择地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址',
                  },
                ],
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址',
                rules: [
                  {
                    required: true,
                    message: '请选择地址',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getForm3() {
      let _this = this
      return {
        title: '景区解说内容',
        type: 'cardForm',
        data: [
          {
            key: 'scenicSpotButton',
            align: 'left',
            name: '景点解说',
            type: 'switch',
            cols: 24,
            maxLength: 150,
            minHeight: '100px',
          },
          {
            key: 'content',
            align: 'left',
            name: '景点解说(语音播报)',
            type: 'textArea',
            cols: 24,
            display: this.detail.scenicSpotButton == '0',
            maxLength: 150,
            minHeight: '100px',
            showCount: true,
            // customRender: (text, records, index) => {
            //   const onInput = (data) => {
            //     records.title = data.target.value
            //   }
            //   return records.isEdit ? <a-input placeholder="" onChange={onInput} value={text} /> : <div>{text}</div>
            // },
          },
          {
            type: 'table',
            showFootButton: false,
            header: () => this.renderTableTop({ name: '内容', onClick: this.onToDrawer }),
            showRowSelect: false,
            showPagination: false,
            havedrag: true,
            cols: 24,
            columns: [
              {
                dataIndex: 'a1',
                align: 'left',
                title: '拖动排序',
                width: 80,
                customRender: function (text, records) {
                  return <a-icon type="menu" />
                },
              },
              {
                dataIndex: 'previewImg',
                align: 'left',
                title: '预览图',
                width: '15%',
                type: 'image',
              },
              {
                dataIndex: 'url',
                align: 'left',
                title: '图片/链接',
                width: '50%',
                customRender: function (text, records) {
                  if (records.bannerType == '0') {
                    return (
                      <img
                        src={text}
                        style={{
                          width: '30px',
                          height: '30px',
                          'border-radius': '6px',
                        }}
                      />
                    )
                  } else {
                    return text
                  }
                },
              },
              {
                dataIndex: 'bannerType',
                align: 'left',
                title: '类型',
                width: 200,
                customRender: function (text) {
                  return ['景点图片', '景点视频', 'VR地址'][+text]
                },
              },
              {
                dataIndex: 'a10',
                align: 'center',
                width: 180,
                title: '操作',
                type: 'buttonGroup',
                typeData: function ({ text, records, index }) {
                  return [
                    {
                      name: '预览',
                      onClick: () => {
                        window.open(records.url, '_blank')
                      },
                    },
                    {
                      name: '编辑',
                      onClick: () => {
                        _this.onToDrawer(records, false, index)
                      },
                    },
                    {
                      name: '删除',
                      type: 'pop',
                      popTitle: '确认是否删除吗?',
                      onClick: () => {
                        _this.detail.list.splice(index, 1)
                      },
                    },
                  ]
                },
              },
            ],
            dataSource: this.detail.list,
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    renderTableTop({ name, onClick }) {
      return (
        <div class="tableTop">
          <div>{name}</div>
          <Button ghost={true} type="primary" style={{ width: '80px', borderRadius: '5px' }} onClick={onClick}>
            <a-icon type="plus" style={{ fontSize: '15px' }} />
            添加
          </Button>
        </div>
      )
    },
    getDrawerForm2(data) {
      return [
        {
          form: [
            {
              name: '类型',
              type: 'select',
              key: 'languageType',
              display: true,
              typeData: this.language_type.map((e) => {
                return {
                  name: e.name,
                  value: e.id,
                }
              }),
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '语音地址',
              type: 'upload',
              maxLength: 1,
              accept: 'audio/mpeg',
              key: 'voiceUrl',
              pass: true,
              display: data.bannerType === '0',
              rules: [
                {
                  required: false,
                  message: '请上传语音文件',
                },
              ],
            },
            {
              name: '详情内容',
              type: 'rich',
              maxLength: 1,
              key: 'content',
              display: ['1', '2'].includes(data.bannerType),
            },
          ],
        },
      ]
    },
    getDrawerForm(data) {
      return [
        {
          form: [
            {
              name: '类型',
              type: 'select',
              key: 'bannerType',
              display: true,
              typeData: [
                {
                  name: '图片',
                  value: '0',
                },
                {
                  name: '视频',
                  value: '1',
                },
                {
                  name: 'VR',
                  value: '2',
                },
              ],
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '景点图片',
              type: 'upload',
              maxLength: 1,
              key: 'url',
              display: data.bannerType === '0',
              rules: [
                {
                  required: true,
                  message: '请上传景点图片',
                },
              ],
            },
            {
              name: '景点视频',
              type: 'uploadVideo',
              maxLength: 1,
              key: 'url',
              display: data.bannerType === '1',
              rules: [
                {
                  required: true,
                  message: '请上传景点视频',
                },
              ],
            },
            {
              name: 'VR链接',
              type: 'input',
              key: 'url',
              display: data.bannerType === '2',
              maxLength: 400,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '预览图(注：展示封面预览图)',
              type: 'upload',
              maxLength: 1,
              key: 'previewImg',
              display: ['1', '2'].includes(data.bannerType),
              rules: [
                {
                  required: true,
                  message: '请上传预览图',
                },
              ],
            },
          ].filter((e) => e.display),
        },
      ]
    },
    onToDrawer(formData = {}, addType = true, index = 0) {
      let formDatacopy = JSON.parse(JSON.stringify(formData))
      apiTool.showDrawer({
        title: addType ? '添加' : '编辑',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: (data) => this.getDrawerForm(data),
          form: formDatacopy,
        },
        success: (form) => this.toSendData(form, index),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    toSendData2({ data, setHidden }, index, addType) {
      if (!addType) {
        this.detail.scenicBlurbList[index] = data
      } else {
        this.detail.scenicBlurbList.push(data)
      }
      setHidden()
      this.$forceUpdate()
    },
    toSendData({ data, setHidden }, index) {
      if (data.id) {
        this.detail.list[index] = data
      } else {
        this.detail.list.push(data)
      }
      setHidden()
      this.$forceUpdate()
    },
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.$route.query.id,
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex((e) => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(20, 40),
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id,
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          getAction('/api/gao', {
            location: `${this.detail.lon},${this.detail.lat}`,
          }).then((res) => {
            let { address, codes } = res.data
            this.detail.areaCode = codes
            this.detail.address = address
            this.$forceUpdate()
          })
          this.$forceUpdate()
        },
      })
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.areaCode[i]
      })
      return obj
    },
    showManageClassify(dataSource) {
      apiTool.showModal({
        width: '800px',
        title: '分类',
        success: ({ data, setHidden }) => {
          try {
            dataSource.forEach((e, i) => {
              if (!e.name || !e.sort) {
                throw new Error('请完善名称/排序！')
              }
            })
          } catch (error) {
            this.$message.warning('请完善名称/排序！')
            return false
          }
          let obj = {
            shopId: this.detail.linkShopId,
            ticketTypeList: dataSource.map((e) => {
              return {
                ...e,
                shopId: this.detail.linkShopId,
              }
            }),
          }
          postAction('/farmTicketType/saveBatch', obj).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功！')
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            type: 'table',
            wrapperCol: { span: 24 },
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                name: null,
                sort: 0,
                productCount: 0,
              }
            },
            columns: [
              {
                dataIndex: 'name',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>名称
                  </div>
                ),
                width: 240,
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.name = data.target.value
                  }
                  return <a-input placeholder="名称" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'sort',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>排序
                  </div>
                ),
                width: 100,
                customRender: function (text, records) {
                  const onInput = (data) => {
                    records.sort = data.target.value
                  }
                  return <a-input type="number" placeholder="" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'productCount',
                align: 'left',
                title: '已关联商品',
                width: 80,
                customRender: function (text, records) {
                  return <div>{text}</div>
                },
              },
              {
                align: 'center',
                width: '10%',
                title: '操作',
                tableType: 'editDelete',
                unShowEditBtn: true,
                disabledKey: 'productCount',
              },
            ],
            dataSource: dataSource,
          },
        ],
      })
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
      ].filter((e) => e)
    },
    getForm4() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={'产品标签'}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    // #endregion 基础设置
    renderDisabled() {
      let val = this.detail.thirdTicket == '1' ? true : this.detail.canBuy == '0' ? true : false
      return val
    },
    getString(str) {
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    /** end 方法 */

    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            try {
              let codeData = this.getSumbitData()
              if (!this.detail.list.length) {
                return this.$message.error('请录入景点解说')
              }
              // 获取标签
              let arr = []
              this.labelGroup.forEach((item) =>
                item.list.forEach((e) => {
                  e['labelTypeCode'] = item.labelTypeCode
                  e['labelTypeId'] = item.id
                  e['labelTypeName'] = item.labelTypeName
                })
              )
              this.labelGroup.map((e) => arr.push(...e.list))
              let brr = arr.map((e) => ({
                ...e,
                czState: e.czState ? 1 : 0,
              }))
              let dwArr = data.dw.split('，')
              data.lon = dwArr[0]
              data.lat = dwArr[1]
              let filterData = this.getFilterData()
              let upDown = data.id ? data.upDown : '0'
              let obj = Object.assign({
                ...data,
                upDown,
                ...codeData,
                businessCycle: filterData.map((e) => e.value).toString(),
                businessCycleName: filterData.map((e) => e.name).toString(),
                farmLabelDataDTOS: brr,
              })

              api.command[data.id ? 'edit' : 'create']
                .call(this, {
                  url: '/farmTicketScenic',
                  params: obj,
                  isPost: false,
                })
                .then((result) => {
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                })
            } catch (error) {
              console.log('报错', error)
            }
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(`/farmTicketScenic/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
          display: this.detail.id,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/farmTicketScenic?id=${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    openLanguageDrawer(formData = {}, addType = true, index = 0) {
      let formDatacopy = JSON.parse(JSON.stringify(formData))
      apiTool.showDrawer({
        title: addType ? '添加' : '编辑',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: (data) => this.getDrawerForm2(data),
          form: formDatacopy,
        },
        success: (form) => this.toSendData2(form, index, addType),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getLanguage() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        header: () =>
          this.renderTableTop({
            name: '国际语言',
            onClick: this.openLanguageDrawer,
          }),
        showRowSelect: false,
        showPagination: false,
        havedrag: true,
        columns: [
          {
            dataIndex: 'languageType',
            align: 'left',
            title: '语言类型',
            width: 60,
            customRender: function (text, records) {
              const item = (that.language_type || []).find((e) => e.id == text) || { name: '' }
              return <div>{item.name}</div>
            },
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 180,
            title: '操作',
            type: 'buttonGroup',
            typeData: ({ text, records, index }) => {
              return [
                {
                  name: '编辑',
                  onClick: () => {
                    this.openLanguageDrawer(records, false, index)
                  },
                },
                {
                  name: '删除',
                  type: 'pop',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    this.detail.scenicBlurbList.splice(index, 1)
                  },
                },
              ]
            },
          },
        ],
        dataSource: this.detail.scenicBlurbList,
      }
    },
    renderTab() {
      return [this.getForm1(), this.getLanguage(), this.getForm2(), this.getForm3(), this.getForm4()]
    },
  },
  render() {
    return (
      <div>
        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            linkShopName: this.storeData,
            thirdType: thirdTypeDataList(),
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 175px);
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 3px;
  > div {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
